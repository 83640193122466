<template>
  <div id="home">
    <div class="pc">
      <el-carousel
        :interval="5000"
        arrow="always"
        :height="57 + 'rem'"
        id="el-carousel"
        ref="carousel"
      >
        <el-carousel-item
          v-for="(item, i) in ImgArr"
          :key="i"
          style="width: 100%; height: 57rem"
        >
          <img :src="item.img" alt="" style="width: 100%; height: 57rem" />
        </el-carousel-item>
      </el-carousel>
      <!-- 公司简介 -->
      <div class="info">
        <h1>公司简介</h1>
        <div class="line"><img src="../../assets/home/line.png" alt="" /></div>
        <p>服务创新、融入企业、同发展同进步</p>
        <div class="content">
          <div class="content_top">
            <img src="../../assets/home/home1.png" alt="" class="home" />
            <div>
              <p>
                四川思可优人力资源有限公司，成立于2018年6月，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急"的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遣一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案。
              </p>
              <p>
                公司依托多年来与各大中型企业良好的合作关系，数百所院校及各地劳动保障部门、人才交流中心紧密合作的资源优势，立志为用工单位输送专业、敬业、具有创新合作意识和良好职业素养的员工，协助企业不断提高员工的综合素质及职业水平，实现员工期望与企业战略相一致的人力资源管理目标。经过几年的沉淀和积累，现有客户30余家，代招人数达到12000人以上，本部派遣员工2800人以上，外包员工1600人以上。
              </p>
            </div>
          </div>
          <!-- <p class="content_bottom">
            成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案
          </p> -->
        </div>
      </div>

      <!-- 发展历程 -->
      <div class="development">
        <h1>荣誉展示</h1>
        <div><img src="../../assets/home/line.png" alt="" /></div>
        <p>以服务求生存 以荣誉促发展</p>
        <div class="content">
          <!-- <p>
            成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案
          </p>
          <img src="../../assets/home/home2.png" alt="" /> -->
          <img src="../../assets/home/jiangbei.jpeg" alt="" />
          <img src="../../assets/home/jiangbei2.jpeg" alt="" />
          <img src="../../assets/home/jiangbei3.jpeg" alt="" />
        </div>
      </div>

      <!-- 专业服务 -->
      <div class="service">
        <h1>专业服务</h1>
        <div><img src="../../assets/home/line.png" alt="" /></div>
        <p>讲究实效、完善管理、提升品质、增创效益</p>
        <ul>
          <li>
            <div class="team">
              <img
                src="https://img.js.design/assets/smartFill/img351164da74c4b8.jpg"
                alt=""
              />
            </div>
            <div class="team_info">
              <h3>人力资源外包</h3>
              <p>产线外包，小时工外包等 <br />零风险，高效能</p>
            </div>
          </li>
          <li>
            <div class="team">
              <img
                src="https://img.js.design/assets/smartFill/img354164da74c4b8.jpg"
                alt=""
              />
            </div>
            <div class="team_info">
              <h3>劳务派遣服务</h3>
              <p>解决用工需求 <br />转移用工风险</p>
            </div>
          </li>
          <li>
            <div class="team">
              <img
                src="https://img.js.design/assets/smartFill/img345164da74c4b8.jpeg"
                alt=""
              />
            </div>
            <div class="team_info">
              <h3>人事代理服务</h3>
              <p>薪酬税务规划 <br />社保、公积金代理</p>
            </div>
          </li>
          <li>
            <div class="team">
              <img
                src="https://img.js.design/assets/smartFill/img347164da74c4b8.jpg"
                alt=""
              />
            </div>
            <div class="team_info">
              <h3>培训与咨询</h3>
              <p>
                制定培训计划<br />
                设计管理咨询方案
              </p>
            </div>
          </li>
        </ul>
      </div>

      <!-- 共同 -->
      <div class="common">
        <h1>
          “立志为用工单位输送专业、敬业、具有创新合作
          <br />意识和良好职业素养的员工”
        </h1>
        <div class="common_btn">
          <div class="common_btn_left">共同成长</div>
          <div class="common_btn_right">共同进步</div>
        </div>
      </div>

      <!-- 品牌理念 -->
      <div class="brand">
        <h1>品牌理念</h1>
        <div><img src="../../assets/home/line.png" alt="" /></div>
        <p>前进方向是公司发展的第一要素</p>
        <div class="china">
          <img src="../../assets/home/China.png" alt="" />
          <div class="brand_info">
            <div>
              <h3>业务覆盖全国</h3>
              <p>
                四川思可优人力资源有限公司自2018年成立初期合作的企业主要集中在成都各大区域及周边范围，发展至今，与思可优合作的企业遍布重庆、宜宾、内江、绵阳、云南、西安、贵州、湖北、河南、江苏等地。致力成为客户信赖，员工有成就感的国内一流人力资源服务企业。
              </p>
            </div>
            <!-- <div style="margin-top: 2rem">
              <h3>10年行业积累</h3>
              <p>
                四川思可优人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”
              </p>
            </div> -->
          </div>
        </div>
      </div>

      <!-- 合作伙伴 -->
      <div class="partner">
        <h1>合作伙伴</h1>
        <div><img src="../../assets/home/line2.png" alt="" /></div>
        <p>多方合作伙伴的大力支持</p>
        <div class="content">
          <!-- <p>
            成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、
          </p> -->
          <div class="company_list">
            <img src="../../assets/home/company1.png" alt="" />
            <img src="../../assets/home/company3.png" alt="" />
            <img src="../../assets/home/company4.png" alt="" />
            <img src="../../assets/home/company5.png" alt="" />
            <img src="../../assets/home/company7.png" alt="" />
            <img src="../../assets/home/company8.png" alt="" />
            <img src="../../assets/home/company9.png" alt="" />
            <img src="../../assets/home/company10.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <img src="../../assets/home/banner.png" alt="" class="banner" />
      <!-- 公司简介 -->
      <div class="info">
        <h1>公司简介</h1>
        <p>服务创新、融入企业、同发展同进步</p>
        <div class="line">
          <img src="../../assets/home/m_line.png" alt="" />
        </div>
        <div class="content">
          <div class="content_top">
            <p>
              四川思可优人力资源有限公司，成立于2018年6月，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急"的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遣一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案。
            </p>
            <img src="../../assets/home/home1.png" alt="" class="home" />
          </div>
          <!-- <p class="content_bottom">
            成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、代理社会保险、管理咨询服务、劳务输出输入等综合配套服务。公司本着“诚实守信，用心服务，急客户之所急＂的服务宗旨，奉行“服务创新，融入企业，共同成长”的理念，坚持以市场需求和客户需求为导向，构建人力资源供应的培训、派遺一体化的经营模式，为各类企业、事业单位提供专业的个性化的第三方人力资源全面解决方案
          </p> -->
        </div>
      </div>

      <!-- 发展历程 -->
      <div class="development">
        <h1>荣誉展示</h1>
        <p>前进方向是公司发展的第一要素</p>
        <div class="line">
          <img src="../../assets/home/m_line.png" alt="" />
        </div>

        <div class="content">
          <img src="../../assets/home/jiangbei.jpeg" alt="" />
          <img src="../../assets/home/jiangbei2.jpeg" alt="" />
          <img src="../../assets/home/jiangbei3.jpeg" alt="" />
        </div>
      </div>

      <!-- 专业服务 -->
      <div class="service">
        <h1>专业服务</h1>
        <p>劳务派遣、人力外包等多方面服务</p>
        <div class="line">
          <img src="../../assets/home/m_line.png" alt="" />
        </div>
        <p>
          成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务、业务外包服务、
        </p>
        <ul>
          <li>
            <div class="team">
              <img
                src="https://img.js.design/assets/smartFill/img351164da74c4b8.jpg"
                alt=""
              />
            </div>
            <div class="team_info">
              <h3>专业团队</h3>
              <p>近千名专业的人力资源老师 为您服务</p>
            </div>
          </li>
          <li>
            <div class="team">
              <img
                src="https://img.js.design/assets/smartFill/img354164da74c4b8.jpg"
                alt=""
              />
            </div>
            <div class="team_info">
              <h3>专业团队</h3>
              <p>近千名专业的人力资源老师 为您服务</p>
            </div>
          </li>
          <li>
            <div class="team">
              <img
                src="https://img.js.design/assets/smartFill/img345164da74c4b8.jpeg"
                alt=""
              />
            </div>
            <div class="team_info">
              <h3>专业团队</h3>
              <p>近千名专业的人力资源老师 为您服务</p>
            </div>
          </li>
          <li>
            <div class="team">
              <img
                src="https://img.js.design/assets/smartFill/img347164da74c4b8.jpg"
                alt=""
              />
            </div>
            <div class="team_info">
              <h3>专业团队</h3>
              <p>近千名专业的人力资源老师 为您服务</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- 共同 -->
      <div class="common">
        <img src="../../assets/home/m_bgc1.png" alt="" class="banner" />
      </div>

      <!-- 品牌理念 -->
      <div class="brand">
        <h1>品牌理念</h1>
        <p>以服务求生存 以荣誉促发展</p>
        <div class="line">
          <img src="../../assets/home/m_line.png" alt="" />
        </div>
        <div class="china">
          <div class="brand_info">
            <img src="../../assets/home/China.png" alt="" />
            <div>
              <h3>业务覆盖全国</h3>
              <p>
                成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务
              </p>
            </div>
          </div>
          <div>
            <h3>10年行业积累</h3>
            <p>
              成都优可人力资源有限公司，成立于2014年，位于天府之国成都市。公司主要从事劳务派遣、人力资源代理招聘、代理薪酬管理、岗位外包服务
            </p>
          </div>
        </div>
      </div>

      <!-- 合作伙伴 -->
      <div class="partner">
        <img src="../../assets/home/m_bgc2.png" alt="" class="banner" />
      </div>
    </div>
  </div>
</template>

<script>
// import scrollReveal from "scrollreveal";
// const staggeringOption1 = {
//   delay: 200,
//   distance: "300px",
//   duration: 500,
//   easing: "ease-in-out",
//   origin: "left",
// };
// const staggeringOption2 = {
//   delay: 200,
//   distance: "300px",
//   duration: 500,
//   easing: "ease-in-out",
//   origin: "right",
// };
export default {
  data() {
    return {
      // scrollReveal: scrollReveal(),
      ImgArr: [
        { img: require("../../assets/home/banner.jpeg") },
        { img: require("../../assets/home/banner3.jpeg") },
        { img: require("../../assets/home/banner2.jpeg") },
      ],
    };
  },
  // mounted() {
  //   this.scrollReveal.reveal(".development .content p", {
  //     ...staggeringOption1,
  //     interval: 300,
  //   });
  //   this.scrollReveal.reveal(".development .content img", {
  //     ...staggeringOption2,
  //     interval: 300,
  //   });
  // },
};
</script>

<style lang="less" scoped>
#home {
  .pc {
    display: block;
    .el-carousel {
      width: 100%;
      .el-carousel-item {
        width: 100%;
      }
    }
    .banner {
      width: 100%;
    }
    .info {
      width: 80%;
      margin: auto;
      color: rgba(252, 252, 252, 1);
      padding-bottom: 88px;
      h1 {
        font-size: 30px;
        padding: 3.5rem 0 1rem 0;
        color: rgba(13, 13, 13, 1);
      }
      p {
        font-size: 14px;
        color: rgba(112, 112, 112, 1);
      }
      .content {
        .content_top {
          display: flex;
          margin-top: 80px;
          .home {
            width: 36.5rem;
            height: 21rem;
          }
          p {
            text-align: left;
            margin-left: 40px;
            text-indent: 28px;
            line-height: 30px;
            font-size: 18px;
          }
        }
        .content_bottom {
          text-align: left;
          text-indent: 28px;
          line-height: 30px;
          color: rgba(0, 0, 0, 1);
          margin-top: 35px;
        }
      }
    }
    .development {
      width: 80%;
      margin: auto;
      color: white;
      padding-bottom: 130px;
      h1 {
        font-size: 30px;
        padding: 3.5rem 0 1rem 0;
        color: rgba(13, 13, 13, 1);
      }
      p {
        font-size: 14px;
        color: rgba(112, 112, 112, 1);
      }
      .content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 80px;
        p {
          text-align: left;
          color: rgba(13, 13, 13, 1);
          text-indent: 28px;
          line-height: 30px;
        }
        img {
          width: 30rem;
          height: 19.5625rem;
          margin-top: 10px;
        }
      }
    }
    .service {
      width: 100%;
      height: 52rem;
      background-image: url(../../assets/home/bgc1.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: -1;
      h1 {
        font-size: 30px;
        padding: 3.5rem 0 1rem 0;
        color: rgba(13, 13, 13, 1);
      }
      p {
        font-size: 14px;
        color: rgba(112, 112, 112, 1);
      }
      ul {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-around;
        margin-top: 8.125rem;
        li {
          width: 24.625rem;
          height: 26.875rem;
          border: 1px solid rgba(166, 166, 166, 1);
          background-color: white;
          list-style: none;
          .team {
            width: 23.4375rem;
            height: 13.75rem;
            background-color: skyblue;
            margin: auto;
            margin-top: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .team_info {
            width: 12rem;
            margin: auto;
            h3 {
              font-size: 22px;
              padding: 2.375rem 0 1.875rem 0;
            }
            p {
              font-size: 1rem;
              color: rgba(56, 56, 56, 1);
            }
          }
        }
      }
    }
    .common {
      width: 100%;
      height: 31.875rem;
      background-image: url(../../assets/home/bgc2.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: -1;
      h1 {
        font-size: 30px;
        padding: 3.5rem 0 1rem 0;
        color: white;
      }
      .common_btn {
        display: flex;
        justify-content: center;
        margin-top: 6rem;
        .common_btn_left {
          width: 174px;
          height: 57px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 20px;
          border-radius: 28.5px;
          background-color: rgba(57, 139, 251, 1);
          z-index: 1;
        }
        .common_btn_right {
          width: 174px;
          height: 57px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 20px;
          border-radius: 28.5px;
          background-color: rgba(0, 57, 138, 1);
          margin-left: -35px;
        }
      }
    }
    .brand {
      width: 90%;
      margin: auto;
      padding-bottom: 50px;
      h1 {
        font-size: 30px;
        padding: 3.5rem 0 1rem 0;
        color: rgba(13, 13, 13, 1);
      }
      p {
        font-size: 14px;
        color: rgba(112, 112, 112, 1);
      }
      .china {
        display: flex;
        align-items: center;
        img {
          margin-top: 52px;
          width: 55.125rem;
          height: 42.25rem;
          margin-right: 40px;
        }
        h3 {
          font-size: 30px;
          color: rgba(57, 139, 251, 1);
          text-align: left;
          margin-bottom: 20px;
        }
        p {
          font-size: 18px;
          color: rgba(56, 56, 56, 1);
          text-align: left;
          line-height: 30px;
        }
      }
    }
    .partner {
      width: 100%;
      height: 652px;
      margin: auto;
      background-image: url(../../assets/home/bgc3.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: -1;
      color: rgba(255, 255, 255, 1);
      h1 {
        font-size: 30px;
        padding: 3.5rem 0 1rem 0;
      }
      p {
        font-size: 14px;
      }
      .content {
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: center;
        margin-top: 5rem;
        p {
          width: 50%;
          text-align: left;
          color: white;
          text-indent: 28px;
          line-height: 30px;
        }
        .company_list {
          width: 75%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-wrap: wrap;
          // margin-left: 40px;
          img {
            width: 14.4375rem;
            height: 9.625rem;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .mobile {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  #home {
    .mobile {
      display: block;
      .banner {
        width: 100%;
      }
      .info {
        width: 90%;
        margin: auto;
        margin-top: 10px;

        h1 {
          font-size: 14px;
          font-weight: bold;
          color: rgba(56, 56, 56, 1);
          text-align: left;
        }
        .line {
          text-align: left;
          img {
            width: 53px;
          }
        }
        p {
          font-size: 10px;
          text-align: left;
        }
        .content {
          .content_top {
            p {
              color: rgba(128, 128, 128, 1);
              font-size: 12px;
              padding: 8px 0 14px 0;
              border-bottom: 0.5px solid rgba(166, 166, 166, 1);
            }
            .home {
              width: 100%;
              margin-top: 15px;
            }
          }
        }
      }
      .development {
        width: 90%;
        margin: auto;
        margin-top: 10px;

        h1 {
          font-size: 14px;
          font-weight: bold;
          color: rgba(56, 56, 56, 1);
          text-align: left;
        }
        .line {
          text-align: left;
          img {
            width: 53px;
          }
        }
        p {
          font-size: 10px;
          text-align: left;
        }
        .content {
          p {
            color: rgba(128, 128, 128, 1);
            font-size: 12px;
            padding: 8px 0 14px 0;
            border-bottom: 0.5px solid rgba(166, 166, 166, 1);
          }
          img {
            width: 100%;
            margin-top: 15px;
          }
        }
      }

      .service {
        width: 90%;
        margin: auto;
        margin-top: 10px;

        h1 {
          font-size: 14px;
          font-weight: bold;
          color: rgba(56, 56, 56, 1);
          text-align: left;
        }
        .line {
          text-align: left;
          img {
            width: 53px;
          }
        }
        p {
          font-size: 10px;
          text-align: left;
        }
        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          list-style: none;
          li {
            width: 160px;
            height: 220px;
            border: 0.5px solid rgba(166, 166, 166, 1);
            margin-top: 15px;
            .team {
              width: 95%;
              margin: auto;
              img {
                width: 100%;
                margin-top: 3px;
              }
            }
            .team_info {
              width: 95%;
              margin: auto;
              h3 {
                text-align: left;
              }
            }
          }
        }
      }
      .common {
        margin-top: 20px;
      }
      .brand {
        width: 90%;
        margin: auto;
        padding-bottom: 15px;
        border-bottom: 0.5px solid rgba(166, 166, 166, 1);
        margin-top: 10px;

        h1 {
          font-size: 14px;
          font-weight: bold;
          color: rgba(56, 56, 56, 1);
          text-align: left;
        }
        .line {
          text-align: left;
          img {
            width: 53px;
          }
        }
        p {
          font-size: 10px;
          text-align: left;
        }
        h3 {
          color: rgba(57, 139, 251, 1);
          font-size: 14px;
          text-align: left;
        }
        p {
          color: rgba(128, 128, 128, 1);
        }
        .brand_info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          img {
            width: 126px;
            height: 96px;
            margin-right: 20px;
          }
        }
      }
      .partner {
        display: flex;
        margin-top: 15px;
      }
    }
    .pc {
      display: none;
    }
  }
}
</style>
